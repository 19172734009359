import { ToggleButton } from "@fluentui/react-components";
import * as powerbi from "powerbi-client";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "../Styles/tabnavigation.css";
import OCPLandingV2 from "./OCPLandingV2/OCPLandingV2";
import ReportCopilot from "./ReportCopilot/ReportCopilot";
import SaveAsDialogBox from "./SaveAsDialogBox";
import SaveAsReportInputBox from "./SaveAsReportInputBox";
import { isTokenExpiry, signOut } from "./helpers/Common";
import { Constants } from "./helpers/Constants";
import protectedAxiosInstance from "./helpers/api";
import DataModel from './DataModel';
import DATAMODELicon from "../Images/img/DATAMODELicon.png"; 
import ReportCatalog from "./ReportCatalog";



interface props {
  trigger: string;
  openDialog: boolean;
  setEditing: (value: boolean) => void;
  handleOpenDialog: (value: boolean) => void;
}

const ReportView: React.FC<props> = ({
  trigger,
  openDialog,
  setEditing,
  handleOpenDialog,
}) => {

  const editableReports: string[] = ["FTMWAP"];
  const env: string = (window.location.host.includes("dev") || window.location.host.includes("localhost")) ? "dev" : "non-dev";
  let workspaceId: string;
  if (window.location.host.includes("dev") || window.location.host.includes("localhost")){
    workspaceId = "c7300506-4e4b-47d2-8d0f-bfdfd6897ec1";
  } else if (window.location.host.includes("uat")){
    workspaceId = "9a95ad97-5af4-4412-8c23-17d789e1170d"; 
  } else{
    workspaceId = "9a95ad97-5af4-4412-8c23-17d789e1170d";
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const [showView, setShowView] = useState<boolean>(false);
  // const tab = searchParams.get("parent") || "";
  const [navList, setNavList] = useState<any>([]);
  const [currentTile, setCurrentTile] = useState<string>("");
  const reportClass = "report-container";
  const [showOCPLanding, setShowOCPLanding] = useState(true);
  const [showReportCatalog, setShowReportCatalog] = useState(false);
  const [selectedTile, setSelectedTile] = useState<string>();
  const [powerBIConfig, setPowerBIConfig] = useState<any>();
  const [embedConfig, setEmbedConfig] = useState<any>();
  const [showReport, setShowReport] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [report, setReport] = useState<powerbi.Report>();
  const [showSaveAsDialog, setShowSaveAsDialog] = useState<boolean>(false);
  const [isCopilotOpen, setIsCopilotOpen] = useState<boolean>(false);
  const [isReportLoaded, setIsReportLoaded] = useState<boolean>(false);
  const [tileLoading, setTileLoading] = useState<string | null>(null);
  const [isDataModelOpen, setIsDataModelOpen] = useState(false); // New state for Data Model toggle
  const [isReload, setIsReload] = useState<boolean>(true);
  
  // const [showReportInputBox, setShowReportInputBox] = useState<boolean>(false)
  // const embedUrlTemp = 'https://app.powerbi.com/reportEmbed?reportId=a192aa91-ad41-43db-99fd-94f045f06c67&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtRC1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19';
  // const act = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkwxS2ZLRklfam5YYndXYzIyeFp4dzFzVUhIMCIsImtpZCI6IkwxS2ZLRklfam5YYndXYzIyeFp4dzFzVUhIMCJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvZTRkOThkZDItOTE5OS00MmU1LWJhOGItZGEzZTc2M2VkZTJlLyIsImlhdCI6MTcxNDYyODY5MiwibmJmIjoxNzE0NjI4NjkyLCJleHAiOjE3MTQ2MzQxNTUsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVlFBcS84V0FBQUFSYW80SXZETXhiWXVFU2xRNkNhZWsxdFB6U1IrMkIzYVovb1ZmU28wZnlCSkZVVklIWU9vMkVPNzA2LzJldTV0SE0yVEJMczlUTThrODd0cFltQ3QxY0Jzczh3bkhDZFRjZVkvTDRsSEVXTT0iLCJhbXIiOlsicHdkIiwibWZhIl0sImFwcGlkIjoiODcxYzAxMGYtNWU2MS00ZmIxLTgzYWMtOTg2MTBhN2U5MTEwIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJLYXBvb3IiLCJnaXZlbl9uYW1lIjoiQ2hpcmFnIiwiaXBhZGRyIjoiMTI1LjIzLjM5LjEzMiIsIm5hbWUiOiJDaGlyYWcgS2Fwb29yIHwgTUFRIFNvZnR3YXJlIiwib2lkIjoiY2Y0MDFlOWUtY2ZlNC00MWJhLTllOGUtOGRmZGZlNWE5M2I0IiwicHVpZCI6IjEwMDMyMDAyRTAwMUM3ODciLCJyaCI6IjAuQVFjQTBvM1o1Sm1SNVVLNmk5by1kajdlTGdrQUFBQUFBQUFBd0FBQUFBQUFBQUFIQUlZLiIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInNpZ25pbl9zdGF0ZSI6WyJrbXNpIl0sInN1YiI6Indua0c5dy1DUUZsSGExQi1iMWhpTi1QU2dsTThXbGR5Y25QY0QzZVA4VUEiLCJ0aWQiOiJlNGQ5OGRkMi05MTk5LTQyZTUtYmE4Yi1kYTNlNzYzZWRlMmUiLCJ1bmlxdWVfbmFtZSI6ImNoaXJhZ2tAbWFxc29mdHdhcmUuY29tIiwidXBuIjoiY2hpcmFna0BtYXFzb2Z0d2FyZS5jb20iLCJ1dGkiOiJOT2gweWFLNXBVcXV4dHF1cEZnUkFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXX0.AmJYHAwJlTs-jN39OfrQ5-Ui4xN-dpblFOwc7d-hnPYH9Zk0vQ8o5CMUI6_juwH-gg-Qk1bR8wMGTtwTbE4uhMlP97Hc6f8tOkj7F-Bp4E2koUXYAyBbsV03xECFp-BZE-KiWbanAMC9DMkCUKFwUoyPwjHhsshioxTp8dBgJwCoMQtYLedVi6jHyhzGMOqOm3oWeaAyBAJfg-gS_DUaTOiPULJWVfnjQvNcPYJ0i-RGycAAMv-cBRh6sB-8CpXpPXy22u6kmu4sKbMTOxxzmglsBwf94BHrjpBCZ3wvE6Gk82UG_AW5MVGqs_xotV4gPCm-BHX3SgKHsvW-xbbhDA'
  // useEffect(() => {
  //     if (openDialog) {
  //         handleEdit()
  //         console.log("Open Dialog in report View", openDialog)
  //     }
  // }, [openDialog])

  // const [reportUrl, setReportUrl] = useState<string>(''); 

  const loadPowerBiConfigSuccess = (response: any) => {
    const tmpObj = {} as any;
    if (response) {
      for (const container in response.Table) {
        tmpObj[response.Table[container]["ContainerID"]] = {
          PowerBIURL: response.Table[container]["PowerBIURL"],
          Page: response.Table[container]["SectionName"],
          FilterTable: response.Table[container]["FilterTable"],
          FilterColumn: response.Table[container]["FilterColumn"],
          FilterType: response.Table[container]["FilterType"],
          PublishTag: response.Table[container]["PublishTag"],
        };
      }
    }
    return tmpObj;
  };

  const changeSearchParams = (tileId: string) => {
    const search = new URLSearchParams(window.location.search);
    const existingTileId = search.get("tileId");
    const existingParent = search.get("parent");
    if (existingTileId !== null && existingTileId !== tileId) {
      search.set("tileId", tileId);
      setSearchParams(search);
    }

    // setSearchParams(searchParams)
  };
  const getEmbedConfig = (tab: string, tileId?: string, tmpConfig?: any) => {
    const accessToken = localStorage.getItem("powerbi_access_token") || "";
    // check token in local storage and if it is expired, logout and log back in. This will regerate the token.
    if (isTokenExpiry()) {
      signOut(`${window.location.pathname + window.location.search}`);
    }
    let tmpEmbedConfig;
    if (!tileId) {
      tileId = tab;
    }
    tmpEmbedConfig = {
      type: "report",
      accessToken: accessToken,
      pageName: tmpConfig[tileId!].Page.length !== 0 ? tmpConfig[tileId!].Page : null,
      embedUrl: tmpConfig[tileId!].PowerBIURL,
      settings: {
        navContentPaneEnabled: false,
        filterPaneEnabled: true,
        persistentFiltersEnabled: true,
        personalBookmarksEnabled: true,
        useCustomSaveAsDialog: true,
        bars: {
          actionBar: {
            visible: true,
          },
        },
      },
    };
    return tmpEmbedConfig;
  };

  const handleEdit = () => {
    if (isTokenExpiry()) {
      signOut(`${window.location.pathname + window.location.search}`);
    }
    let tmpConfig;
    if (edit) {
      // When switching from edit mode to view mode, remove viewMode and permissions
      setShowDialogBox(true);
      setEditing(false);
      handleOpenDialog(true);
    } else {
      // When switching to edit mode, add viewMode and permissions back
      tmpConfig = {
        ...embedConfig,
        viewMode: models.ViewMode.Edit,
        permissions: powerbi.models.Permissions.Copy,
      };
      setEditing(true);
      setEdit(true);
      setEmbedConfig(tmpConfig);
    }
  };
  const handleSave = () => {
    console.log("Edit Saved");
    setShowDialogBox(false);
    setShowSaveAsDialog(true);
  };

  const handleSaveAs = async (reportName: string) => {
    if (reportName == "") {
      alert("Please enter a valid report name");
      return;
    }
    setShowSaveAsDialog(false);
    let x: any;
    if (report) {
      try {
        const reportData = await report.saveAs({
          name: reportName,
          targetWorkspaceId: "e3986208-c900-4cb4-a007-32cd4ff1f27d",
        });
        console.log("Report Saved As", reportName, reportData, report);
        // check after 10s if the report is saved
        setTimeout(async () => {
          const isReportSaved = await report.isSaved();
          console.log("Is Saved", isReportSaved);
          setShowSaveAsDialog(false);
          setEdit(false);
          setEditing(false);
          handleOpenDialog(false);
        }, 3000);
      } catch (error) {
        console.error("Error while saving report", error);
      }
    }
  };

  const handleDiscard = () => {
    let tmpConfig;
    const { viewMode, permissions, ...rest } = embedConfig;
    tmpConfig = rest;
    console.log("Edit Discarded");
    setEmbedConfig(tmpConfig);
    setShowDialogBox(false);
    setEdit(false);
    setEditing(false);
    handleOpenDialog(false);
  };

  useEffect(() => {
    if (isReportLoaded && powerBIConfig[selectedTile!]) {
      report?.setPage(powerBIConfig[selectedTile!]?.Page);
    }
  }, [isReportLoaded]);

  const setEmbeddedConfiguration = async (tileId: string, idx: number) => {
    setIsDataModelOpen(false);
    setShowReport(true);
    setTileLoading(tileId);
    try {
      if (powerBIConfig[tileId!]?.PowerBIURL != embedConfig?.embedUrl) {
        setEmbedConfig({
          ...embedConfig,
          embedUrl: powerBIConfig[tileId!].PowerBIURL,
          pageName: powerBIConfig[tileId!].Page,
        });
      } else if (isReportLoaded) {
        await report?.setPage(powerBIConfig[tileId!].Page);
      } else {
        // if the page change is initated before the report is loaded, 
        // the page change will be done in the isReportLoaded useEffect
        // see above useEffect
      }

      setTileLoading(null);
      changeSearchParams(tileId);
      setSelectedTile(tileId);
    } catch (error) {
      setTileLoading(null);
      console.error(error);
    }
  };

  const setInitialParameters = (navlist: any) => {
    if (navlist) {
      const tileId = navlist[0].tileId;
      const search = new URLSearchParams(window.location.search);
      //search.set("tileId", tileId)
      searchParams.set("tileId", tileId);
      //setSearchParams(search)
    }
  };

  const setData = async (tmpConfig: any, tab: string, tile?: string) => {
    let defaultTileId;
    let horizontalNavList;

    try {
      const response = await protectedAxiosInstance.get("/api/service/GetHorizontalTileList", {
        withCredentials: true,
      });
      horizontalNavList = JSON.parse(
        JSON.parse(response.data.Table[0].ListData)
      );
      const navlist = horizontalNavList[tab || ""];
      setNavList(navlist);
      setPowerBIConfig(tmpConfig);

      if (tile != undefined) {
        defaultTileId = tile;
        setSelectedTile(defaultTileId);
      } else {
        defaultTileId = navlist && navlist.length > 0 ? navlist[0].tileId : tab;
        if (navlist && navlist.length > 0) setSelectedTile(navlist[0].tileId);
        setInitialParameters(navlist);
      }
      setEmbedConfig(getEmbedConfig(tab, defaultTileId, tmpConfig));
      setShowReport(true);
    } catch (error) {
      console.error("Error", error);
    }
    // setHorizontalNavList(tab)
  };

  const getUpdateReportConfiguration = (tileId: string) => {
    let tileText: string = "";
    let url = window.location.host;
    // do all this in one foreach and when you get the tiletext break the loop
    // get tiletext for this tileId from Constants.NAV_TILE_LIST
    const navlist =
      url.includes("prod") || url.includes("ftbi.microsoft.com")
        ? Constants.NAV_TILE_LIST.home
        : Constants.NAV_TILE_LIST_NON_PROD.home;
    navlist.forEach((nav: any) => {
      if (nav.tileId === tileId) {
        tileText = nav.tileText;
      }
      if (nav.hasSubPages) {
        nav.subPages.forEach((sub: any) => {
          if (sub.tileId === tileId) {
            tileText = sub.tileText;
          }
          if (sub.hasSubPages) {
            sub.subPages.forEach((subsub: any) => {
              if (subsub.tileId === tileId) {
                tileText = subsub.tileText;
              }
            });
          }
        });
      }
    });
    return tileText;
  };
  const updateRecentReports = async (value: string) => {
    // let currentReports = AppDataFactory().getRecentReports();
    let newReport = value;
    let payload;
    let toBeUpdatedReports = "";
    const idToken = localStorage.getItem("id_token");
    try {
      let currentReports = await protectedAxiosInstance.get("api/service/GetRecentReports", {
        withCredentials: true,
      });
      let ReportString =
        currentReports.data.Table.length > 0
          ? currentReports.data.Table[0].RecentReports
          : "";
      //let ReportString = "";
      if (ReportString.length > 0) {
        let reports: string[] = ReportString.split("$|$");
        if (ReportString.indexOf(newReport) >= 0) {
          reports.splice(reports.indexOf(newReport), 1);
          reports.unshift(newReport);
          toBeUpdatedReports = reports.join("$|$");
        } else if (reports.length >= 10) {
          reports = reports.slice(0, 9);
          reports.unshift(newReport);
          toBeUpdatedReports = reports.join("$|$");
        } else {
          toBeUpdatedReports = newReport + "$|$" + ReportString;
        }
      } else {
        toBeUpdatedReports = newReport;
      }

      const user = localStorage.getItem("user");
      const jsonData = JSON.parse(user || "");
      payload = {
        UserAlias: jsonData.mail,
        Reports: toBeUpdatedReports,
      };
      try {
        await protectedAxiosInstance.post("api/Service/UpdateRecentReports", payload, {
          withCredentials: true,
        });
      } catch (error) {
        console.error("Error while updating recent reports", error);
      }
    } catch (error) {
      console.error("Error while getting recent reports", error);
    }
  };

  const fetchNSetData = (tab: string, tile: string) => {
    const jsonResponse = localStorage.getItem("powerbi_config")
      ? JSON.parse(localStorage.getItem("powerbi_config")!)
      : null;
    const tmpConfig = loadPowerBiConfigSuccess(jsonResponse);
    if (tile != null) {
      setData(tmpConfig, tab!, tile);
    } else {
      setData(tmpConfig, tab!);
    }
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const tab = search.get("parent");
    const tile = search.get("tileId");
    const prevSearch = new URLSearchParams(sessionStorage.getItem("previousPage") || "");
    // setIsReportLoaded(false);
    setEdit(false);

    if (!search.size) {
      setShowReport(false);
      setShowReportCatalog(false);
      setShowOCPLanding(true);
    
    } else if (tab == "ReportCatalog_SearchReport") {
      setShowOCPLanding(false);
      setShowReport(false);
      setShowReportCatalog(true);
    
    } else if (isReload || !prevSearch.size || prevSearch.get("parent") !== tab ){
      setShowOCPLanding(false);
      setShowReportCatalog(false);
      setIsDataModelOpen(false);
      
      
      fetchNSetData(tab!, tile!);
      setCurrentTile(tab!);
      
      // setShowReport(true); // Commenting it out. This should be logically correct but it is causing the report to break. 
                              // Another setShowReport is added in fetchNSetData > setData function which is working fine.
      setIsReportLoaded(false);

      // getRecentReports(); // This is not used anywhere
      const tiletext = getUpdateReportConfiguration(tab!);
      setTimeout(() => {
        updateRecentReports(tab + "#|#" + tiletext);
      }, 2000);
    }
    
    sessionStorage.setItem("previousPage", window.location.search);
    setIsReload(false);
    // return () => {
    //   setShowReport(false);
    // };
  }, [trigger, window.location.search]);

  useEffect(() => {
    report?.on("saveAsTriggered", () => {
      setShowSaveAsDialog(true);
    });
    report?.on("saved", () => {
      setEdit(false);
      setEditing(false);
      console.log("In Report Saved Event");
    });
  }, [
    powerbi.Report.allowedEvents,
    powerbi.Report.accessTokenAttribute,
    report,
  ]);

  return (
    <>

      {showDialogBox && (
        <SaveAsDialogBox
          message="You have unsaved changes. Do you want to save them?"
          onCancel={() => {
            setShowDialogBox(false);
            handleOpenDialog(false);
            setEditing(true);
          }}
          onConfirm={handleSave}
          onDiscard={handleDiscard}
        />
      )}
      {showSaveAsDialog && (
        <SaveAsReportInputBox
          onSaveAs={handleSaveAs}
          onCancel={() => {
            setShowSaveAsDialog(false);
            handleOpenDialog(false);
            setEditing(true);
          }}
        />
      )}
      {showOCPLanding ? (
        <OCPLandingV2 />
      ) : showReportCatalog ? (
        <ReportCatalog />
      ) : (
        <div className="Reportingsection" id="EmbeddReportCotainer">
          <div className="fourthLevelnavigation">
            <ul className="tabLevelul" style={{ borderBottom: "2px grey", flex: 1 }}>
              {navList &&
                navList.map((nav: any, idx: number) => (
                  <li
                    key={idx}
                    className={`tabLevelli ${
                      selectedTile === nav.tileId ? "selectedTabLevelli" : ""
                    }${
                      tileLoading === nav.tileId ? "loadingTabLevelli" : ""
                    }`}
                    onClick={async () => await setEmbeddedConfiguration(nav.tileId, idx)}
                  >
                    {nav.tileText}
                  </li>
                ))}
            </ul>
            
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {env === "dev" &&  (
                <ToggleButton
                  appearance="subtle"
                  checked={isCopilotOpen}
                  onClick={() => setIsCopilotOpen(!isCopilotOpen)}
                  icon={<img src="https://res-2-sdf.cdn.office.net/files/fabric-cdn-prod_20240411.001/assets/brand-icons/product/svg/copilot_24x1.svg"></img>}
                >
                Copilot
                </ToggleButton>
              )}

              <ToggleButton
                appearance="subtle"
                checked={isDataModelOpen}
                onClick={() =>{
                  setIsReportLoaded(false);
                  setShowReport(!showReport);
                  setIsDataModelOpen(!isDataModelOpen)}}
                icon={<img src={DATAMODELicon} alt="Data Model Icon" style={{ height: '20px', width: '20px' }} />}
              >
                Data Model
              </ToggleButton>
            </div>

            {env === "dev" && editableReports.includes(currentTile) && (
              <span onClick={handleEdit} className="align-right">
                {!edit ? (
                  <span>
                    <i
                      className="ms-Icon ms-Icon--EditSolid12 pr-1"
                      aria-hidden="true"
                    ></i>{" "}
                    Edit
                  </span>
                ) : (
                  `View mode`
                )}
              </span>
            )}

          </div>
          <hr className="tabseparator" id="tabalign" />
          <div>
            <div className="OCPScorecardContainer">
              <div className="ReportViewSection">
                {/* Toggle between DataModel and PowerBIEmbed components */}
                {isDataModelOpen && embedConfig ? (
                  <DataModel
                    reportId = {new URL(embedConfig.embedUrl).searchParams.get('reportId') || ""}
                    workspaceId = {workspaceId}
                  />
                ) : (
                <div className="ReportContainer" style={{display: "flex"}} >
                  <div className="ReportViewSectionContent HorizontalTab" style={{flex: "1"}} >
                    {showReport && embedConfig && (
                      <PowerBIEmbed
                        eventHandlers = {
                          new Map([
                            ['loaded', function () {setIsReportLoaded(true)}],
                            ['error', function (event) {console.log("PBI Error: ", event?.detail);}],
                          ])
                        }  
                        embedConfig={embedConfig}
                        cssClassName={reportClass}
                        getEmbeddedComponent={(embedObject: powerbi.Embed) => {
                          setReport(embedObject as powerbi.Report);
                        }}
                      />
                    )}
                  </div>
                  {isCopilotOpen && report && 
                    <ReportCopilot reportEmbed={report}/>
                  }
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportView;
